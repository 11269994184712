import { forwardRef } from "react"

import { PLANS_LIST } from "../utils/constants"
import { useAppSelector } from "../redux/hooks"

import PlanItem from "./PlanItem"

const Plans = forwardRef<HTMLDivElement>((_, ref) => {
    const subscription = useAppSelector((state) => state.app.subscription)

    return (
        <div ref={ref} className="plans-wrapper">
            <div className="plans__text-block">
                <h5 className="plans__title">Plans for Your Need</h5>
                <p className="plans__description">Select best plan, ensuring a perfect match.</p>
            </div>
            <div className="plans__list">
                {PLANS_LIST.map((plan) => {
                    if (plan.name === 'Free' && subscription) return null

                    return (
                        <PlanItem key={plan.id} plan={plan} />
                    )
                })}
            </div>
        </div>
    )
})

export default Plans