import { useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useState } from "react"

import { useAuthContext } from "../context/AuthContext"

import DarkGradientBox from "../components/DarkGradientBox"

import PasswordInput from "../assets/password-input.svg"
import EmailInput from "../assets/email-input.svg"
import HideEye from "../assets/hide-eye.svg"
import ShowEye from "../assets/show-eye.svg"
import GoogleSvg from "../assets/google.svg"
import BackSvg from "../assets/back.svg"

type TAuthData = {
    email: string,
    password: string
}

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [searchParams] = useSearchParams()

    const { signUpWithEmailPassword, authWithGoogle } = useAuthContext()

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setError,
    } = useForm<TAuthData>()

    const handleAuth = async (data: TAuthData) => {
        const result = await signUpWithEmailPassword(data.email, data.password)

        if (!result) {
            setError('email', { type: 'manual', message: 'Email address invalid or already in use.' })
        } else {
            navigate(searchParams.get('fromPlan') ? '/#upgrade' : '/profile')
        }
    }

    return (
        <div className="auth-page-wrapper">
            <div className="auth-page__left">
                <img onClick={() => navigate('/')} className="auth-page__back" src={BackSvg} alt="" />
                <div className="auth-title-block">
                    <h5 className="auth-title">Get Started <span className="text-yellow">Free!</span></h5>
                    <p className="auth-title-label">start your journey with us</p>
                </div>
            </div>
            <div className="auth-page__right">
                <h5 className="auth-page__title">Sign <span className="text-yellow">Up</span></h5>
                <form autoComplete="off" onSubmit={handleSubmit(handleAuth)} className="auth-form-wrapper">
                    <div className="auth-input-block-wrapper">
                        <span className="auth-input-label">Email</span>
                        <DarkGradientBox>
                            <img src={EmailInput} alt="" />
                            <input
                                type="text"
                                className="auth-input"
                                placeholder="Email"
                                autoComplete="off"
                                {...register("email", {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Invalid email address'
                                    }
                                })}
                            />
                        </DarkGradientBox>
                    </div>
                    <div className="auth-input-block-wrapper">
                        <span className="auth-input-label">Password</span>
                        <DarkGradientBox>
                            <img src={PasswordInput} alt="" />
                            <input
                                type={showPassword ? "text" : "password"}
                                className="auth-input"
                                placeholder="Password"
                                autoComplete="off"
                                {...register("password", {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 6,
                                        message: 'Password must be at least 6 characters long'
                                    }
                                })}
                            />
                            {showPassword ? (
                                <img onClick={() => setShowPassword(false)} className="auth-input-show-hide-eye" src={ShowEye} alt="" />
                            ) : (
                                <img onClick={() => setShowPassword(true)} className="auth-input-show-hide-eye" src={HideEye} alt="" />
                            )}
                        </DarkGradientBox>
                    </div>
                    <button type="submit" className="auth-button">
                        Sign Up
                    </button>
                </form>
                <div className="auth-or-continue-with-block">
                    Or continue with
                </div>
                <div onClick={authWithGoogle} className="auth-google__button-wrapper">
                    <DarkGradientBox
                        additionalStyles={{ width: '44px', height: '44px', padding: '0', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <button className="auth-google-button">
                            <img style={{ marginTop: '3px' }} width={24} src={GoogleSvg} alt="" />
                        </button>
                    </DarkGradientBox>
                </div>
                <div className="auth-switch__text">
                    Already have an account? <span onClick={() => navigate(searchParams.get('fromPlan') ? '/sign-in?fromPlan=true' : '/sign-in')} className="text-yellow-button">Sign In</span>
                </div>
            </div>
        </div>
    )
}

export default SignUp