import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FC, MouseEvent, useEffect, useState } from 'react'

import { useCheckCouponStatusMutation } from '../api/appApi'
import { TCoupon } from '../types/types'

type StripeFormProps = {
    setIsPaymentError: (value: boolean) => void,
    planName: string,
    setDiscountCode: (value: TCoupon) => void,
    setDiscountValue: (value: string) => void,
    discountValue: string,
    discountCode: TCoupon | null
}

const StripeForm: FC<StripeFormProps> = ({
    setIsPaymentError,
    planName,
    setDiscountCode,
    setDiscountValue,
    discountValue,
    discountCode
}) => {
    const [isDiscountError, setIsDiscountError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const elements = useElements();
    const stripe = useStripe();

    const [checkDiscountCode, { isError }] = useCheckCouponStatusMutation()

    const handleCheckDiscountCode = async () => {
        setIsLoading(true)
        const { data } = await checkDiscountCode(discountValue)

        if (data) {
            setDiscountCode(data.coupon)
        }
        setIsLoading(false)
    }

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/checkout/success?plan=${planName}`,
            },
        })

        if (error) {
            setIsPaymentError(true)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isError) {
            setIsDiscountError(true)
        }
    }, [isError])

    return (
        <div className='stripe-form'>
            <PaymentElement id="payment-element" />
            <div className="stripe-form-discount">
                <input
                    placeholder="Discount Code"
                    className={`stripe-form-discount-input ${discountCode ? 'discount-applied' : ''} ${isDiscountError ? 'discount-error' : ''}`}
                    type="text"
                    value={discountValue}
                    onChange={(e) => { setDiscountValue(e.target.value); setIsDiscountError(false) }}
                    readOnly={!!discountCode}
                />
                {discountCode ? (
                    <div className='stripe-form-discount-applied'>
                        {discountCode.percent_off}% off
                    </div>
                ) : (
                    <button onClick={handleCheckDiscountCode} className="stripe-form-discount-button">
                        Apply
                    </button>
                )}
            </div>
            <button
                disabled={isLoading}
                onClick={(e) => handleSubmit(e)}
                className='stripe-button__pay'
            >
                Purchase
            </button>
        </div>
    )
}

export default StripeForm