import { Routes, Route } from 'react-router-dom';

import { useAppSelector } from './redux/hooks';

import TermsAndConditions from './pages/TermsAndConditions';
import CheckoutSuccess from './pages/CheckoutSuccess';
import ResetPassword from './pages/ResetPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import Checkout from './pages/Checkout';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Home from './pages/Home';

import './styles.css';

function App() {
  const subscription = useAppSelector((state) => state.app.subscription)

  return (
    <div className="application-wrapper">
      {subscription ? (
        <Routes>
          <Route path='*' element={<Home />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/checkout/:planName/:priceId' element={<Checkout />} />
          <Route path='/checkout/success' element={<CheckoutSuccess />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/contact-us' element={<ContactUs />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='*' element={<Home />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/checkout/success' element={<CheckoutSuccess />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
