import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useState } from "react"

import { useAuthContext } from "../context/AuthContext"

import DarkGradientBox from "../components/DarkGradientBox"

import EmailInput from "../assets/email-input.svg"
import EmailSent from "../assets/email-sent.svg"
import BackSvg from "../assets/back.svg"

type TAuthData = {
    email: string
}

const ResetPassword = () => {
    const [emailSent, setEmailSent] = useState(false)

    const { sendResetPasswordEmail } = useAuthContext()

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
    } = useForm<TAuthData>()

    const handleReset = async (data: TAuthData) => {
        const result = await sendResetPasswordEmail(data.email)

        if (result) {
            setEmailSent(true)
        }
    }

    return (
        <div className="auth-page-wrapper">
            <div className="auth-page__left">
                <img onClick={() => navigate('/')} className="auth-page__back" src={BackSvg} alt="" />
                <div className="auth-title-block">
                    <h5 className="auth-title">Reset <span className="text-yellow">Password</span></h5>
                    <p className="auth-title-label">enter an email to reset password</p>
                </div>
            </div>
            <div className="auth-page__right">
                {emailSent ? (
                    <>
                        <img style={{ margin: '0 auto' }} width={80} src={EmailSent} alt="" />
                        <h5 className="reset-email-sent-title">
                            Email with reset link was <br />
                            sent to your <span className='text-yellow'>email address</span>
                        </h5>
                        <button onClick={() => navigate('/sign-in')} className="auth-button">
                            Continue to Log In
                        </button>
                    </>
                ) : (
                    <>
                        <h5 className="auth-page__title">Reset <span className="text-yellow">Password</span></h5>
                        <form autoComplete="off" onSubmit={handleSubmit(handleReset)} className="auth-form-wrapper">
                            <div className="auth-input-block-wrapper">
                                <span className="auth-input-label">Email</span>
                                <DarkGradientBox>
                                    <img src={EmailInput} alt="" />
                                    <input
                                        type="text"
                                        className="auth-input"
                                        placeholder="Email"
                                        autoComplete="off"
                                        {...register("email", {
                                            required: 'Email is required',
                                        })}
                                    />
                                </DarkGradientBox>
                            </div>
                            <button type="submit" className="auth-button">
                                Reset
                            </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    )
}

export default ResetPassword