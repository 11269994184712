import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../redux/store';

export const ACCESS_TOKEN_INVALID_MESSAGE = 'Given token not valid for any token type';
export const TOKEN_NOT_PROVIDED = 'Authentication credentials were not provided.';

const BASE_URL = `https://aice.sp-lutsk.com/api/`

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }: { getState: () => unknown }) => {
        const accessToken = (getState() as RootState).app?.user?.accessToken;
        const tempAccessToken = (getState() as RootState).app?.accessToken;

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        } else if (tempAccessToken) {
            headers.set('Authorization', `Bearer ${tempAccessToken}`);
        }

        return headers;
    },
});