import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

import Logo from '../assets/logo_summarie.png';
import UserSvg from '../assets/user.svg';

const Header = () => {
    const subscription = useAppSelector((state) => state.app.subscription);
    const navigate = useNavigate();

    const handleNavigateToHome = () => {
        navigate('/');
    };

    return (
        <div className="header-wrapper">
            <div onClick={handleNavigateToHome} className="header__logo">
                <img width={50} src={Logo} alt="Summarie Logo" />
                <h2 className="logo__text">Summarie</h2>
            </div>
            <div className="header_buttons">
                <input type="checkbox" id="side-menu" className="side-menu-checkbox" />
                <label className="hamburger" htmlFor="side-menu">
                    <span className="hamburger-line"></span>
                </label>
                <div className="menu">
                    {subscription ? (
                        <div onClick={() => navigate('/profile')} className="header__profile">
                            <img className="header__profile-icon" src={UserSvg} alt="Profile Icon" />
                        </div>
                    ) : (
                        <>
                            <button onClick={() => navigate('/sign-in')} className="button__login">Login</button>
                            <button onClick={() => navigate('/sign-up')} className="button__create-account">Create an Account</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;