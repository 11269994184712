export const PLANS_LIST = [
    {
        id: 1,
        name: 'Free',
        price: '0.00',
        per: '',
        description: 'We offer free trial to access all features for 10 requests',
        priceId: '',
        features: [
            '10 requests',
            'Analyze website pages',
            'Analyze PDF documents',
            'Chat Based Analysis',
        ],
    },
    {
        id: 2,
        name: 'Pro',
        price: '5.00',
        per: '/mo',
        description: 'For the people who want to try our service and access all features',
        priceId: process.env.REACT_APP_STRIPE_PRO_PLAN_PRICE_ID || '',
        features: [
            'Unlimited access',
            'Analyze website pages',
            'Analyze PDF documents',
            'Chat Based Analysis',
        ],
    },
    {
        id: 3,
        name: 'Pro Annual',
        price: '50.00',
        per: '/year',
        description: 'For the Pro users who often work with big texts and want to simplify process',
        priceId: process.env.REACT_APP_STRIPE_PRO_ANNUAL_PLAN_PRICE_ID || '',
        features: [
            'Unlimited access',
            'Analyze website pages',
            'Analyze PDF documents',
            'Chat Based Analysis',
        ],
    },
]

export const SUMMARIE_BENEFITS = [
    'Summarie provides quick summaries of lengthy articles, saving users valuable time.',
    'By condensing information, Summarie helps users grasp key points more effectively.',
    'Users can focus on essential content without getting bogged down by irrelevant details.',
    'The chat feature facilitates real-time interaction, making content discussions more dynamic and interactive.',
    'The chat feature facilitates real-time interaction, making content discussions more dynamic and interactive.',
    'Students can use Summarie to study more efficiently and engage in academic discussions about the material.'
]