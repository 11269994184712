import { SUMMARIE_BENEFITS } from "../utils/constants"

const WhySummarie = () => {
    return (
        <div className="why-summarie-wrapper">
            <div className="why-summarie__web-background">
                <div className="why-summarie__text-block">
                    <h5 className="why-summarie__title">Why you should choose <span className="text-yellow">Summarie?</span></h5>
                    <p className="why-summarie__description">Lets find out our benefits together</p>
                </div>
                <div className="why-summarie__benefits">
                    {SUMMARIE_BENEFITS.map((benefit, index) => (
                        <div key={index} className="why-summarie__benefit">
                            <h5 className="benefit__number">0{index + 1}</h5>
                            <p className="benefit__text">{benefit}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhySummarie