import React, { CSSProperties, FC } from 'react'

type TDarkGradientBoxProps = {
    children: React.ReactNode,
    additionalStyles?: CSSProperties,
}

const DarkGradientBox: FC<TDarkGradientBoxProps> = ({ children, additionalStyles }) => {
    return (
        <div className='dark-gradient-box-border-wrapper'>
            <div className='dark-gradient-box-background-wrapper'>
                <div style={additionalStyles} className='dark-gradient-box-wrapper'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DarkGradientBox