import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'

// Reducers
import rootReducers from './rootReducer'

// RTK Query API
import { appApi } from '../api/appApi';

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
        appApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type TypedDispatch<T> = ThunkDispatch<T, any, Action>;
