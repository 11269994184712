import { FC } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useAppSelector } from "../redux/hooks"
import { Plan } from "../types/types"

import Check from "../assets/check.svg"

type PlanItemProps = {
    plan: Plan
}

const PlanItem: FC<PlanItemProps> = ({ plan }) => {
    const [searchParams] = useSearchParams('')
    const subscription = useAppSelector((state) => state.app.subscription)

    const navigate = useNavigate()

    const handleUpgrade = (plan: Plan) => {
        if (plan.name === 'Free') {
            navigate('/sign-up')
        } else {
            const discount = searchParams.get('discount') ? searchParams.get('discount') : localStorage.getItem('discount')

            if (subscription) {
                navigate(`/checkout/${plan.name}/${plan.priceId}${discount ? `?discount=${discount}` : ''}`)
            } else {
                navigate('/sign-up?fromPlan=true')
            }
        }
    }

    return (
        <div className="plan-item-wrapper">
            <h5 className="plan-item__name">{plan.name}</h5>
            <p className="plan-item__price">${plan.price}{plan.per}</p>
            <p className="plan-item__description">{plan.description}</p>
            <span className="plan-item__underline"></span>
            <div className="plan-item__options">
                {plan.features.map((feature, index) => (
                    <div key={index} className="plan-item__option">
                        <img width={20} src={Check} alt="Check" />
                        <p>{feature}</p>
                    </div>
                ))}
            </div>
            <span className="plan-item__underline"></span>
            <button
                onClick={() => handleUpgrade(plan)}
                className="plan-item__button"
            >
                Select Plan
            </button>
        </div>
    )
}

export default PlanItem