import { createApi } from "@reduxjs/toolkit/query/react";

import { TSubscription } from "../redux/reducers/appSlice";
import { TCoupon } from "../types/types";
import { baseQuery } from "./apiConfig";

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: baseQuery,
    tagTypes: ['UserInfo'],
    endpoints: builder => ({
        getUserInfo: builder.query<TSubscription, void>({
            query: () => {
                return {
                    url: `payments/me/`,
                    method: 'GET',
                }
            },
            providesTags: ['UserInfo']
        }),
        createSubscription: builder.mutation<{ client_secret: string }, { price_id: string, coupon_code: string | undefined }>({
            query: (data) => {
                return {
                    url: `payments/create_subscription/`,
                    method: 'POST',
                    body: data,
                }
            },
        }),
        cancelSubscription: builder.mutation<{ message: string }, void>({
            query: () => {
                return {
                    url: `payments/cancel_subscription/`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: ['UserInfo']
        }),
        deleteAccount: builder.mutation<{ message: string }, void>({
            query: () => {
                return {
                    url: `payments/delete_account/`,
                    method: 'POST',
                }
            },
        }),
        checkCouponStatus: builder.mutation<{ coupon: TCoupon }, string>({
            query: (coupon) => {
                return {
                    url: `payments/check_coupon/`,
                    method: 'POST',
                    body: { coupon_code: coupon },
                }
            }
        })
    })
})

export const {
    useGetUserInfoQuery,
    useCreateSubscriptionMutation,
    useCancelSubscriptionMutation,
    useDeleteAccountMutation,
    useCheckCouponStatusMutation
} = appApi