import { useSearchParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { jwtDecode } from 'jwt-decode';

import { setAccessToken, setSubscription, setUser } from "../redux/reducers/appSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { appApi, useCancelSubscriptionMutation, useDeleteAccountMutation, useGetUserInfoQuery } from "../api/appApi"
import { useAuthContext } from "../context/AuthContext";
import { DecodedUser } from "../types/types";

import Header from "../components/Header"

import ProfileSvg from '../assets/profile.svg'
import CardSvg from '../assets/card.svg'

const Profile = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const subscription = useAppSelector((state) => state.app.subscription)
    const accessToken = useAppSelector((state) => state.app.accessToken)
    const user = useAppSelector((state) => state.app.user)

    const dispatch = useAppDispatch()

    const { logOut } = useAuthContext()

    const decodedJwt: DecodedUser | undefined = useMemo(() => {
        if (accessToken) {
            return jwtDecode(accessToken)
        }
    }, [accessToken])

    const { refetch } = useGetUserInfoQuery()
    const [cancelSubscription] = useCancelSubscriptionMutation()
    const [deleteAccount] = useDeleteAccountMutation()

    const handleLogout = () => {
        localStorage.removeItem('tempToken')
        appApi.util.resetApiState()
        appApi.util.invalidateTags(['UserInfo'])
        dispatch(setAccessToken(null))
        dispatch(setUser(null))
        dispatch(setSubscription(null))
        logOut()
    }

    const handleDeleteAccount = async () => {
        await deleteAccount()
        handleLogout()
    }

    const handleDownload = () => {
        window.open('https://chrome.google.com/webstore/detail/summarie-web-page-analyze/mmlfjhglbocbjagniablhjnmcdlfmihc', '_blank')
    }

    useEffect(() => {
        if (searchParams.get('reload')) {
            if (subscription?.plan === 'Free') {
                setTimeout(() => {
                    try {
                        refetch()
                    } catch (error) {
                        console.error(error)
                    }
                }, 2000)
            } else {
                setSearchParams({})
            }
        }
    }, [searchParams, setSearchParams, subscription, refetch])

    return (
        <div className="profile-wrapper">
            <Header />
            <div className="profile__content">
                <div className="profile_download">
                    Download our Chrome Extension to get started
                    <button onClick={handleDownload} className="profile_download-button">Download</button>
                </div>
                <h5 className="profile__title">Manage your <span className="text-yellow">Information</span></h5>
                <div className="profile__info-block">
                    <div className="profile-info__header">
                        <h5 className="profile-info__title">
                            <img width={40} src={ProfileSvg} alt="" />
                            Profile Information
                        </h5>
                        <button onClick={handleDeleteAccount} className="profile-info__change-plan">Delete Profile</button>
                    </div>
                    <div className="profile-info__info-row">
                        <label className="profile-info__label">First Name</label>
                        <span className="profile-info__value">{user ? user.displayName?.split(' ')[0] : decodedJwt?.name?.split(' ')[0]}</span>
                    </div>
                    <div className="profile-info__info-row">
                        <label className="profile-info__label">Last Name</label>
                        <span className="profile-info__value">{user ? user.displayName?.split(' ')[1] : decodedJwt?.name?.split(' ')[1]}</span>
                    </div>
                    <div className="profile-info__info-row">
                        <label className="profile-info__label">Email Address</label>
                        <span className="profile-info__value">{user ? user.email : decodedJwt?.email}</span>
                    </div>
                </div>
                <div className="profile__info-block">
                    <div className="profile-info__header">
                        <h5 className="profile-info__title">
                            <img width={38} src={CardSvg} alt="" />
                            Plan Information
                        </h5>
                        {subscription?.plan !== 'Free' && (
                            <button disabled={subscription?.is_cancelled} onClick={() => cancelSubscription()} className="profile-info__change-plan">Cancel Plan</button>
                        )}
                    </div>
                    <div className="profile-info__info-row">
                        <label className="profile-info__label">Plan Name</label>
                        <span className="profile-info__value">{subscription?.plan}</span>
                    </div>
                    <div className="profile-info__info-row">
                        <label className="profile-info__label">Usage Remaining</label>
                        <span className="profile-info__value">{subscription?.plan === 'Free' ? subscription?.requests_remaining : 'Unlimited'}</span>
                    </div>
                    {subscription?.is_cancelled && (
                        <div className="profile-info__subscription-canceled">Your subscription is canceled. After the end of the billing period, it will be changed to Free.</div>
                    )}
                </div>
                <button onClick={handleLogout} className="profile-info__change-plan">Logout</button>
            </div>
        </div>
    )
}

export default Profile