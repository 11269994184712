import Summary from '../assets/summary.mp4'
import YouTubeSvg from '../assets/youtube.svg'

const YouTube = () => {
    const handleNavigateToExtension = () => {
        window.open('https://chrome.google.com/webstore/detail/summarie-web-page-analyze/mmlfjhglbocbjagniablhjnmcdlfmihc', '_blank');
    }

    return (
        <div className="youtube-wrapper">
            <div className="youtube__text-block">
                <h6 className="youtube__title">What is the Summarie?</h6>
                <p className="youtube__description">
                    Watch this video to learn about what makes us useful in web surfing
                </p>
                <button onClick={handleNavigateToExtension} className="youtube__button">
                    Download Extension
                </button>
            </div>
            <div className="youtube__video-block">
                <div className="youtube__video-container">
                    <video className="youtube__video" poster={YouTubeSvg} controls>
                        <source src={Summary} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}

export default YouTube;