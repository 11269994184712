import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from 'react';

import { useCheckCouponStatusMutation, useCreateSubscriptionMutation } from '../api/appApi';
import { PLANS_LIST } from '../utils/constants';
import { TCoupon } from '../types/types';

import StripeForm from '../components/StripeForm';
import Header from '../components/Header';
import Footer from '../components/Footer';

import BlackCheckSvg from '../assets/black-check.svg'
import CloseSvg from '../assets/close.svg'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const Checkout = () => {
    const [discountCode, setDiscountCode] = useState<TCoupon | null>(null)
    const [isPaymentError, setIsPaymentError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams('')
    const [discountValue, setDiscountValue] = useState('')
    const [clientSecret, setClientSecret] = useState('')

    const { planName, priceId } = useParams()

    const navigate = useNavigate()

    const selectedPlan = PLANS_LIST.find(plan => plan.name === planName)

    const [createSubscription, { isError, isLoading }] = useCreateSubscriptionMutation()
    const [checkDiscountCode] = useCheckCouponStatusMutation()

    const appearance = {
        variables: {
            spacingUnit: '4px',
        },
        rules: {
            '.Tab': {
                border: '1px solid #E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Input': {
                border: '1px solid #FFFFFF',
                backgroundColor: '#FFFFFF',
                padding: '14px 16px',
                outline: 'none',
                marginBottom: '3px',
                color: '#161c2d'
            },

            '.Label': {
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '18px',
                color: '#8C8C8C',
                fontVariant: 'normal',
                marginBottom: '3px',
                fontFamily: 'Archivo, sans-serif',
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: '#E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            '.Input::placeholder': {
                color: '#8C8C8C',
                fontSize: '16px',
            },

            '.TermsText': {
                color: '#8C8C8C',
                fontSize: '12px',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isError) {
            navigate('/sign-up')
        }
    }, [isError, navigate])

    useEffect(() => {
        (async () => {
            if (priceId) {
                if (searchParams.get('discount')) {
                    const { data } = await checkDiscountCode(searchParams.get('discount') || '')

                    if (data) {
                        setDiscountCode(data?.coupon)
                        setDiscountValue(searchParams.get('discount') || '')
                        setSearchParams({})
                    } else {
                        setSearchParams({})
                    }
                } else {
                    const res = await createSubscription({ price_id: priceId, coupon_code: discountCode?.id })

                    if (res?.data?.client_secret) {
                        setClientSecret(res.data.client_secret)
                    }
                }
            }
        })()
    }, [priceId, createSubscription, discountCode, searchParams, checkDiscountCode, setSearchParams])

    return (
        <div className='checkout-wrapper'>
            <Header />
            <div className='checkout__form'>
                <div className="checkout-left-side">
                    {isPaymentError && (
                        <div className='checkout__error'>
                            <span className='checkout__error-text'>An error occurred while processing your payment. Please try again</span>
                            <img onClick={() => setIsPaymentError(false)} className='checkout__error-icon' src={CloseSvg} alt="" />
                        </div>
                    )}
                    <h5 className="checkout-left__title">Let's Make <span className='text-yellow'>Payment</span></h5>
                    <p className='checkout-left__description'>
                        To start your subscription, input your card details to make payment.
                        <br />
                        You can cancel any time from your dashboard.
                    </p>
                    {clientSecret && !isLoading ? (
                        <Elements key={clientSecret} options={options} stripe={stripePromise}>
                            {priceId && planName && (
                                <StripeForm
                                    setIsPaymentError={setIsPaymentError}
                                    setDiscountValue={setDiscountValue}
                                    setDiscountCode={setDiscountCode}
                                    discountValue={discountValue}
                                    discountCode={discountCode}
                                    planName={planName}
                                />
                            )}
                        </Elements>
                    ) : (
                        <div className='checkout__loading'>
                            <div className="spinner"></div>
                            <span className='checkout__loading-text'>Loading payment information</span>
                        </div>
                    )}
                </div>
                <div className="checkout-right-side">
                    <div className='checkout-right__paying'>
                        <span className='checkout-right__you-are-paying'>You are paying,</span>
                        <p className='checkout-right__price'>
                            ${discountCode ? Number(selectedPlan?.price) - Number(selectedPlan?.price) / 100 * discountCode?.percent_off : selectedPlan?.price}
                        </p>
                    </div>
                    <div className='checkout-right__payment-info'>
                        <div className='checkout-right__info-item'>
                            <span className='checkout-right__info-label'>{planName} Plan</span>
                            <span className='checkout-right__info-value'>${selectedPlan?.price}</span>
                        </div>
                        <div className='checkout-right__info-item'>
                            <span className='checkout-right__info-label'>Tax</span>
                            <span className='checkout-right__info-value'>$0.00</span>
                        </div>
                        {discountCode && (
                            <div className='checkout-right__info-item'>
                                <span className='checkout-right__info-label'>Discount</span>
                                <span className='checkout-right__info-value'>{discountCode?.percent_off}%</span>
                            </div>
                        )}
                        <div className='checkout-right__info-underline'></div>
                        <div className='checkout-right__info-item'>
                            <span className='checkout-right__info-label'>Total</span>
                            <span className='checkout-right__info-value'>
                                ${discountCode ? Number(selectedPlan?.price) - Number(selectedPlan?.price) / 100 * discountCode?.percent_off : selectedPlan?.price}
                            </span>
                        </div>
                    </div>
                    <div className='checkout-right__plan-options'>
                        <p className='checkout-right__options-title'>Plan Options</p>
                        <div className='checkout-right__info-underline'></div>
                        {selectedPlan?.features.map((feature, index) => (
                            <div key={index} className='checkout-right__option'>
                                <img src={BlackCheckSvg} alt="" />
                                <span className='checkout-right__option-text'>{feature}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer customStyles={{ borderTop: '1px solid #8C8C8C' }} />
        </div>
    )
}

export default Checkout