import { createSlice } from '@reduxjs/toolkit'
import { User as FirebaseUser } from "firebase/auth";
import { appApi } from '../../api/appApi';

export type User = FirebaseUser & {
    accessToken: string,
}

export type TSubscription = {
    plan: string,
    requests_remaining: number,
    created_at: string,
    updated_at: string,
    is_cancelled: boolean
}

type TAppState = {
    user: User | null,
    accessToken: string | null,
    subscription: TSubscription | null
}

const initialState: TAppState = {
    user: null,
    accessToken: null,
    subscription: null
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload
        },
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload
        },
        setSubscription: (state, { payload }) => {
            state.subscription = payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            appApi.endpoints.getUserInfo.matchFulfilled,
            (state, { payload }) => {
                state.subscription = payload
            }
        )
    }
})

export const { setUser, setAccessToken, setSubscription } = appSlice.actions

export default appSlice.reducer