import useWeb3forms from "@web3forms/react";
import { useForm } from "react-hook-form";

import DarkGradientBox from "../components/DarkGradientBox";
import Header from "../components/Header"

const ContactUs = () => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const { submit: onSubmit } = useWeb3forms({
        access_key: process.env.REACT_APP_WEB3_FORMS_API_KEY || "",
        onSuccess() { reset(); },
        onError() { }
    });

    return (
        <div className="contact-us-wrapper">
            <Header />
            <form onSubmit={handleSubmit(onSubmit)} className="contact-us-form">
                <h1 className="contact-us-title">Contact <span className="text-yellow">Us</span></h1>
                <DarkGradientBox>
                    <input
                        type="text"
                        placeholder="Name"
                        className="auth-input"
                        {...register("name", { required: true })}
                    />
                </DarkGradientBox>
                {errors.name && <span className="auth-text-error">This field is required</span>}
                <DarkGradientBox>
                    <input
                        type="email"
                        placeholder="Email"
                        className="auth-input"
                        {...register("email", { required: true, pattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g })}
                    />
                </DarkGradientBox>
                {errors.email && <span className="auth-text-error">Enter a valid email</span>}
                <DarkGradientBox>
                    <textarea
                        placeholder="Message"
                        className="auth-input"
                        {...register("message", { required: true })}
                    />
                </DarkGradientBox>
                {errors.message && <span className="auth-text-error">This field is required</span>}
                <button type="submit" className="contact-us__submit">Submit</button>
            </form>
        </div>
    )
}

export default ContactUs