import { CSSProperties, FC } from "react";
import { Link } from "react-router-dom";

import InstagramSvg from "../assets/instagram.svg";
import LinkedinSvg from "../assets/linkedin.svg";
import FacebookSvg from "../assets/facebook.svg";
import Logo from "../assets/logo_summarie.png";

type FooterProps = {
    customStyles?: CSSProperties;
}

const Footer: FC<FooterProps> = ({ customStyles }) => {
    return (
        <div style={customStyles} className="footer-wrapper">
            <div className="footer__top-info">
                <div className="footer-top__links">
                    <img width={50} src={Logo} alt="" />
                    <Link className="footer__link" to="/terms-and-conditions">Terms and Conditions</Link>
                    <Link className="footer__link" to="/privacy-policy">Privacy Policy</Link>
                    <Link className="footer__link" to="/contact-us">Contact Us</Link>
                </div>
                <div className="footer-top__socials">
                    <a
                        className="footer__link"
                        href="https://www.linkedin.com/company/sp-lutsk/posts/?feedView=all"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={LinkedinSvg} alt="Instagram" />
                    </a>
                    <a
                        className="footer__link"
                        href="https://www.facebook.com/SoftwareProductionLutsk"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={FacebookSvg} alt="Instagram" />
                    </a>
                    <a
                        className="footer__link"
                        href="https://www.instagram.com/sp_lutsk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={InstagramSvg} alt="Instagram" />
                    </a>
                </div>
            </div>
            <div className="footer__bottom-copyright">
                Copyright © {new Date().getFullYear()} | Summarie | All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer