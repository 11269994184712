import { combineReducers } from "@reduxjs/toolkit"

// Reducers
import appReducer from './reducers/appSlice'

// RTK Query API
import { appApi } from "../api/appApi"

const rootReducers = combineReducers({
    app: appReducer,
    [appApi.reducerPath]: appApi.reducer,
})

export default rootReducers
