import { Link } from "react-router-dom"
import { useEffect } from "react"

import Header from "../components/Header"

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="agreement-page-wrapper">
            <Header />
            <div className="agreement-page__content">
                <div className="agreement-page__small-gap-block">
                    <h5 className="agreement__big-title">Terms of Service</h5>
                    <p className="agreement__effective-date">Effective Date: Jun 07, 2024</p>
                </div>
                <span className="agreement__default-text">
                    Welcome to Summarie, a Chrome extension developed by <span className="agreement__yellow-color">SP-Lutsk International LLC</span> (“we,” “us,” or “our”). These Terms of Service (“Terms”) govern your use of the Summarie Chrome extension (the “Extension”). By installing and using the Extension, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the Extension.
                </span>
                <h5 className="agreement__title">1. Description of the Extension</h5>
                <p className="agreement__default-text">
                    The Extension uses AI technology to generate summaries of web page content that you visit. The summaries are created locally on your device, and no web page content is transmitted to our servers.
                </p>
                <h5 className="agreement__title">2. Eligibility</h5>
                <p className="agreement__default-text">
                    You must be at least 13 years old to use the Extension. By using the Extension, you represent and warrant that you meet this age requirement.
                </p>
                <h5 className="agreement__title">3. License</h5>
                <p className="agreement__default-text">
                    Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use the Extension for your personal, non-commercial use.
                </p>
                <h5 className="agreement__title">4. Restrictions</h5>
                <p className="agreement__default-text">
                    You agree not to:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">Use the Extension for any illegal or unauthorized purpose</li>
                    <li className="agreement-list-item__text">
                        Modify, adapt, translate, reverse engineer, decompile, or disassemble any part of the Extension
                    </li>
                    <li className="agreement-list-item__text">
                        Remove, alter, or obscure any proprietary notices or labels on the Extension
                    </li>
                    <li className="agreement-list-item__text">
                        Use the Extension in any manner that could interfere with, disrupt, or negatively affect other users
                    </li>
                </ul>
                <h5 className="agreement__title">5. Intellectual Property</h5>
                <p className="agreement__default-text">
                    All intellectual property rights in the Extension, including but not limited to copyrights, trademarks, and trade secrets, are owned by <span className="agreement__yellow-color">SP-Lutsk International LLC</span> or its licensors. You do not acquire any ownership rights by using the Extension.
                </p>
                <h5 className="agreement__title">6. Privacy</h5>
                <p className="agreement__default-text">
                    Your privacy is important to us. Please review our <Link style={{ color: 'var(--white-color)' }} to='/privacy-policy' >Privacy Policy</Link> to understand how we collect, use, and protect your information when you use the Extension.
                </p>
                <h5 className="agreement__title">7. Disclaimer of Warranties</h5>
                <p className="agreement__default-text">
                    The Extension is provided “as is” and “as available,” without warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the Extension will be uninterrupted, error-free, or free of harmful components.
                </p>
                <h5 className="agreement__title">8. Limitation of Liability</h5>
                <p className="agreement__default-text">
                    To the maximum extent permitted by applicable law, <span className="agreement__yellow-color">SP-Lutsk International LLC</span> shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">
                        Your use of or inability to use the Extension
                    </li>
                    <li className="agreement-list-item__text">
                        Any unauthorized access to or use of our servers and/or any personal information stored therein
                    </li>
                    <li className="agreement-list-item__text">
                        Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Extension by any third party
                    </li>
                    <li className="agreement-list-item__text">
                        Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Extension
                    </li>
                </ul>
                <h5 className="agreement__title">9. Indemnification</h5>
                <p className="agreement__default-text">
                    You agree to indemnify, defend, and hold harmless <span className="agreement__yellow-color">SP-Lutsk International LLC</span>, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney’s fees, arising out of or in any way connected with your access to or use of the Extension.
                </p>
                <h5 className="agreement__title">10. Changes to the Terms</h5>
                <p className="agreement__default-text">
                    We may modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website and updating the effective date. You are advised to review these Terms periodically for any changes. Your continued use of the Extension after the changes take effect will constitute your acceptance of the revised Terms.
                </p>
                <h5 className="agreement__title">11. Termination</h5>
                <p className="agreement__default-text">
                    We may terminate or suspend your access to the Extension, without prior notice or liability, for any reason, including but not limited to your breach of these Terms. Upon termination, your right to use the Extension will immediately cease.
                </p>
                <h5 className="agreement__title">12. Governing Law</h5>
                <p className="agreement__default-text">
                    These Terms and your use of the Extension will be governed by and construed in accordance with the laws of the state of Delaware, without regard to its conflict of laws principles.
                </p>
                <h5 className="agreement__title">13. Contact Us</h5>
                <p className="agreement__default-text">
                    If you have any questions or concerns about these Terms or the Extension, please contact us at:
                </p>
                <p className="agreement__default-text">
                    <span className="agreement__yellow-color">SP-Lutsk International LLC</span>
                </p>
                <p className="agreement__default-text">
                    summarie@sp-lutsk.com
                </p>
                <p className="agreement__default-text">
                    By using the Extension, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
                </p>
                <p className="agreement__default-text">
                    SP-Lutsk International LLC is registered in the state of Delaware, USA. For more information about our company and services, please visit our website.
                </p>
            </div>
        </div>
    )
}

export default TermsAndConditions