import { useAppSelector } from '../redux/hooks'
import { useNavigate } from 'react-router-dom'

import RobotSvg from '../assets/robot.png'

const Introduction = () => {
    const user = useAppSelector((state) => state.app.user)

    const navigate = useNavigate()

    const navigateToSignUpOrExtensionPage = () => {
        if (user) {
            window.open('https://chrome.google.com/webstore/detail/summarie-web-page-analyze/mmlfjhglbocbjagniablhjnmcdlfmihc', '_blank');
        } else {
            navigate('/sign-up')
        }
    }

    return (
        <div className="introduction-wrapper">
            <h5 className="introduction__heading">
                Unveiling Intelligent Analysis for
                <br />
                Enhanced <span className="text-yellow">Web Performance</span>
            </h5>
            <p className="introduction__description">
                Optimize your website with cutting-edge intelligent performance.
            </p>
            <button onClick={navigateToSignUpOrExtensionPage} className="introduction__button">
                Get Started
            </button>
            <div className='introduction__robot'>
                <span className='robot__shadow'></span>
                <img width={600} src={RobotSvg} alt="" />
            </div>
        </div>
    )
}

export default Introduction