import React, { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    signInWithPopup,
    User
} from "firebase/auth";

import { setAccessToken, setSubscription, setUser } from "../redux/reducers/appSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { auth, googleProvider } from "../firebase/firebase";
import { useGetUserInfoQuery } from "../api/appApi";

export type AuthContextType = {
    loading: boolean;
    signUpWithEmailPassword: (
        email: string,
        password: string
    ) => Promise<User | undefined>;
    logInWithEmailPassword: (email: string, password: string) => Promise<User | undefined>;
    logOut: () => void;
    authWithGoogle: () => Promise<void>;
    sendResetPasswordEmail: (email: string) => Promise<boolean>;
};

const AuthContext = createContext({} as AuthContextType);

export const useAuthContext = () => useContext<AuthContextType>(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const user = useAppSelector((state) => state.app.user);
    const accessToken = useAppSelector((state) => state.app.accessToken);

    const dispatch = useAppDispatch()

    useGetUserInfoQuery(undefined, { skip: !user && !accessToken, refetchOnMountOrArgChange: true })

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser(user));
            } else {
                dispatch(setUser(null));
            }
        });

        const tokenRefreshInterval = 300000;
        const tokenRefreshTimer = setInterval(async () => {
            const currentUser = auth.currentUser

            if (currentUser) {
                const tokenResult = await currentUser.getIdTokenResult();

                if (tokenResult) {
                    const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
                    const currentTime = new Date().getTime();

                    const timeToExpiration = tokenExpirationTime - currentTime;

                    if (timeToExpiration < tokenRefreshInterval) {
                        try {
                            const newToken = await currentUser.getIdToken(true);

                            dispatch(setUser({
                                ...currentUser,
                                accessToken: newToken
                            } as User));
                        } catch (error) {
                            console.log('Error refreshing ID token:', error);
                        }
                    }
                }
            }
        }, 100000);

        setLoading(false);

        return () => {
            unsubscribe();
            clearInterval(tokenRefreshTimer)
        }
    }, [dispatch]);

    const authWithGoogle = async () => {
        await signInWithPopup(auth, googleProvider);
    };

    const signUpWithEmailPassword = async (email: string, password: string) => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);

            return res.user;
        } catch (e) {
            return undefined;
        }
    };

    const logInWithEmailPassword = async (email: string, password: string) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);

            return res.user;
        } catch (e) {
            return undefined;
        }
    };

    const logOut = async () => {
        dispatch(setUser(null));
        dispatch(setSubscription(null));
        dispatch(setAccessToken(null));
        await signOut(auth);
    };

    const sendResetPasswordEmail = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);

            return true;
        } catch (e) {
            return false;
        }
    }

    const value = {
        loading,
        signUpWithEmailPassword,
        logInWithEmailPassword,
        logOut,
        authWithGoogle,
        sendResetPasswordEmail,
    }

    return (
        <AuthContext.Provider
            value={value}
        >
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
