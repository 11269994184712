import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import Header from "../components/Header"

import SuccessCheck from '../assets/success-check.svg'

const CheckoutSuccess = () => {
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()

    const handleNavigateToProfile = () => {
        navigate('/profile?reload=true')
    }

    useEffect(() => {
        const planName = searchParams.get('planName')

        if (!planName) return

        window.gtag('event', 'conversion', {
            'send_to': 'AW-16608544455/2OK8CIDwxsEZEMeNye89', // # pragma: allowlist secret
            'value': planName === 'Pro' ? 5 : 50,
            'currency': 'USD',
        });
    }, [searchParams])

    return (
        <div className="checkout-success">
            <Header />
            <div className="checkout-success__content">
                <img width={120} src={SuccessCheck} alt="" />
                <h5 className="checkout-success__text">
                    Payment <span className="text-yellow">Successful!</span>
                    <br />
                    Thank you for your purchase.
                </h5>
                <button onClick={handleNavigateToProfile} className="checkout-success__button">Go to Profile</button>
            </div>
        </div>
    )
}

export default CheckoutSuccess