import { useEffect } from "react"

import Header from "../components/Header"

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="agreement-page-wrapper">
            <Header />
            <div className="agreement-page__content">
                <div className="agreement-page__small-gap-block">
                    <h5 className="agreement__big-title">Privacy Policy</h5>
                    <p className="agreement__effective-date">Effective Date: Jun 07, 2024</p>
                </div>
                <span className="agreement__default-text">
                    <span className="agreement__yellow-color">SP-Lutsk International LLC</span> (“we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information when you use our Chrome extension, “Summarie” (the “Extension”). We are committed to complying with all relevant privacy laws and regulations, including those applicable in the state of Delaware and the United States.
                </span>
                <h5 className="agreement__title">1. Information We Collect</h5>
                <h6 className="agreement__subtitle">
                    a. Automatically Collected Information
                </h6>
                <p className="agreement__default-text">
                    When you use the Extension, we may automatically collect certain information, including but not limited to:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">Browser type and version</li>
                    <li className="agreement-list-item__text">Operating system</li>
                    <li className="agreement-list-item__text">IP address</li>
                    <li className="agreement-list-item__text">Pages visited and the time spent on those pages</li>
                </ul>
                <h6 className="agreement__subtitle">
                    b. Information from Web Pages
                </h6>
                <p className="agreement__default-text">
                    The Extension processes the content of web pages you visit to generate summaries. While the content is processed locally on your device, no web page content is sent to our servers or stored by us.
                </p>
                <h5 className="agreement__title">2. How We Use the Information </h5>
                <p className="agreement__default-text">
                    We use the information we collect to:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">Provide and improve the functionality of the Extension</li>
                    <li className="agreement-list-item__text">Diagnose technical issues and ensure the security of the Extension</li>
                    <li className="agreement-list-item__text">
                        Understand usage patterns and preferences to enhance user experience
                    </li>
                </ul>
                <h5 className="agreement__title">3. Legal Basis for Processing</h5>
                <p className="agreement__default-text">
                    We process your information based on the following legal grounds:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">
                        <span className="agreement__yellow-color">Consent:</span> By using the Extension, you consent to our processing of your information as described in this Privacy Policy.
                    </li>
                    <li className="agreement-list-item__text">
                        <span className="agreement__yellow-color">Legitimate Interests:</span> We process your information to fulfill our legitimate interests in providing and improving the Extension, provided that those interests are not overridden by your rights and interests.
                    </li>
                </ul>
                <h5 className="agreement__title">4. Data Sharing and Disclosure</h5>
                <p className="agreement__default-text">
                    We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share information only in the following circumstances:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">
                        <span className="agreement__yellow-color">Service Providers:</span> We may share information with trusted third-party service providers who assist us in operating our Extension, provided they agree to keep this information confidential and comply with applicable privacy laws.
                    </li>
                    <li className="agreement-list-item__text">
                        <span className="agreement__yellow-color">Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid requests by public authorities, including to meet national security or law enforcement requirements.
                    </li>
                </ul>
                <h5 className="agreement__title">5. Data Security</h5>
                <p className="agreement__default-text">
                    We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security.
                </p>
                <h5 className="agreement__title">6. Your Rights and Choices</h5>
                <h6 className="agreement__subtitle">
                    a. Access and Correction
                </h6>
                <p className="agreement__default-text">
                    You have the right to access and correct your personal information. You may contact us to request access to, correct, or delete any personal information that you have provided to us.
                </p>
                <h6 className="agreement__subtitle">
                    b. Opt-Out
                </h6>
                <p className="agreement__default-text">
                    You can manage your privacy preferences by:
                </p>
                <ul className="agreement__items-list">
                    <li className="agreement-list-item__text">
                        Adjusting your browser settings to control cookies and other tracking technologies
                    </li>
                    <li className="agreement-list-item__text">
                        Disabling the Extension at any time through your browser’s settings
                    </li>
                </ul>
                <h6 className="agreement__subtitle">
                    c. Do Not Track
                </h6>
                <p className="agreement__default-text">
                    Our Extension does not respond to “Do Not Track” signals. However, you can configure your browser settings to send “Do Not Track” signals.
                </p>
                <h5 className="agreement__title">7. Children’s Privacy</h5>
                <p className="agreement__default-text">
                    The Extension is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.
                </p>
                <h5 className="agreement__title">8. Changes to This Privacy Policy</h5>
                <p className="agreement__default-text">
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.
                </p>
                <h5 className="agreement__title">9. International Data Transfers</h5>
                <p className="agreement__default-text">
                    Your information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country. We take appropriate measures to ensure that your personal information remains protected and complies with this Privacy Policy.
                </p>
                <h5 className="agreement__title">10. Contact Us</h5>
                <p className="agreement__default-text">
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </p>
                <p className="agreement__default-text">
                    <span className="agreement__yellow-color">SP-Lutsk International LLC</span>
                </p>
                <p className="agreement__default-text">
                    summarie@sp-lutsk.com
                </p>
                <p className="agreement__default-text">
                    By using the Extension, you consent to the collection and use of information as described in this Privacy Policy.
                </p>
                <p className="agreement__default-text">
                    <span className="agreement__yellow-color">SP-Lutsk International LLC</span> is registered in the state of Delaware, USA. For more information about our company and services, please visit our website.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy